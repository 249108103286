import * as React from 'react'
import debounce from 'lodash.debounce'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StoreContext } from '../context/store-context'
import { formatPrice } from '../utils/format-price'
import { getShopifyImage } from 'gatsby-source-shopify'
import { XIcon } from '@heroicons/react/solid'

export function LineItem({ product }) {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(product.quantity)

  const variantImage = {
    ...product.variant.image,
    originalSrc: product.variant.image.src,
  }
  const price = formatPrice(
    product.variant.priceV2.currencyCode,
    Number(product.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    product.variant.priceV2.currencyCode,
    Number(product.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, product.id)
  }

  const uli = debounce(
    value => updateLineItem(checkout.id, product.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== '' && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        crop: 'contain',
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )
  return (
    <li key={product.id} className="flex py-6 sm:py-10 bg-beige rounded-xl p-6 mb-4 text-left">
      <div className="flex-shrink-0">
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? product.variant.title}
            className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
          />
        )}
      </div>

      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
          <div>
            <div className="flex justify-between">
              <h3 className="text-sm">
                <a href={product.href} className="text-xl font-bold text-gray-700 hover:text-gray-800">
                  {product.title}
                </a>
              </h3>
            </div>
            <div className="mt-1 flex text-sm">
              <p className="text-xl text-gray-500">{product.variant.title === 'Default Title'
                ? ''
                : product.variant.title}</p>
            </div>
            <p className="mt-1 text-sm font-light text-gray-900">{price}</p>
          </div>

          <div className="mt-4 sm:mt-0 sm:pr-9">
            <select
              disabled={loading}
              value={quantity}
              id={`quantity-${product.id}`}
              name={`quantity-${product.id}`}
              className="block max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-abel-green focus:border-abel-green sm:text-sm"
              onChange={(e) => handleQuantityChange(e.currentTarget.value)}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </select>

            <div className="absolute top-0 right-0">
              <button onClick={handleRemove}
                type="button"
                className="ml-4 text-sm font-medium text-gray-600 hover:text-gray-500 sm:ml-0 sm:mt-3">
                <span className="sr-only">Remove</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </li >
  )
}
