import * as React from 'react'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import { StoreContext } from '../context/store-context'
import { LineItem } from '../components/line-item'
import { formatPrice } from '../utils/format-price'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'

const hrefLangLinks = {
  en: 'cart',
  fr: 'cart',
  nl: 'cart',
  de: 'cart',
}

const CartPage = () => {
  const { t } = useTranslation()
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }
  return (
    <Layout hrefLangLinks={hrefLangLinks}>
      <Container>
        {emptyCart ? (
          <div>
            <SectionTitle title={t('cart-your-cart')} />
            <p>
              {t('cart-emtpy')}
            </p>
          </div>
        ) : (
          <>
            <h1 className="text-3xl font-extrabold text-center tracking-tight text-gray-900 sm:text-4xl">
              <SectionTitle title="Shopping Cart" />
            </h1>

            <div className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-6 lg:items-start xl:gap-x-8">
              <section aria-labelledby="cart-heading" className="lg:col-span-7">
                <h2 id="cart-heading" className="sr-only">
                  {t('cart-items-in-cart')}
                </h2>
                <ul role="list" className="space-y-4">
                  {checkout.lineItems.map(product => (
                    <LineItem product={product} key={product.id} />
                  ))}
                </ul>
              </section>

              {/* Order summary */}
              <section
                aria-labelledby="summary-heading"
                className="mt-16 bg-beige rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
              >
                <h2
                  id="summary-heading"
                  className="text-lg font-medium text-left text-gray-900"
                >
                  {t('cart-order-summary')}
                </h2>

                <div>
                  <dl className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <dt className="text-base text-gray-600">{t('cart-subtotal')}</dt>
                      <dd className="text-base text-gray-900">
                        {formatPrice(
                          checkout.totalPriceV2.currencyCode,
                          checkout.totalPriceV2.amount
                        )}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                      <dt className="flex items-center text-base text-gray-600">
                        {t('shipping-title')}
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        {t('cart-free')}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                      <dt className="text-base font-medium text-gray-900">
                        {t('cart-order-total')}
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        {' '}
                        {formatPrice(
                          checkout.totalPriceV2.currencyCode,
                          checkout.totalPriceV2.amount
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>

                <div className="mt-10">
                  <button
                    onClick={handleCheckout}
                    disabled={loading}
                    type="submit"
                    className="w-full bg-abel-green border border-transparent rounded-full shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    {t('cart-checkout')}
                  </button>
                </div>

                <div className="mt-6 text-sm text-center">
                  <p>
                    {t('cart-or')}{' '}
                    <a
                      href="#"
                      className="text-abel-green font-medium hover:text-gray-500"
                    >
                      {t('cart-continue')}<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </p>
                </div>
              </section>
            </div>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default CartPage
